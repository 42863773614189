<template lang="pug">
  div.row
    div.col-12
      button.btn.btn-primary.mx-2(type="button", @click="new_node") New
      button.btn.btn-primary.mx-2(type="button", @click="recalculate") Recalculate Score
      button.btn.btn-primary.mx-2(type="button", @click="migrate") Migration
    div.col-12
      div.table-responsive
        table.table.table-striped.users-table
          thead
            tr
              th ID
              th Label
              th Global IP
              th Local IP
              th Port
              th region_id
              th Score
          tbody
            tr(v-for="n in nodes", :key="n.id", @click="show_jobs(n.id)")
              td {{ n.id }}
              td {{ n.label }}
              td {{ n.global_ip }}
              td {{ n.local_ip }}
              td {{ n.port }}
              td {{ n.region_id }}
              td {{ n.score }}

    div.container-fluid.d-none#new-node
      div.row
        div.col
          h5 New Monitor Node
      div.row
        div.col.mb-3
          label ID
          input.form-control(type="text", v-model.number="new_node_data.id")
        div.col.mb-3
          label Label
          input.form-control(type="text", v-model="new_node_data.label")
      div.row
        div.col.mb-3
          label Global IP
          input.form-control(type="text", v-model="new_node_data.global_ip")
      div.row
        div.col.mb-3
          label Local IP
          input.form-control(type="text", v-model="new_node_data.local_ip")
          span.form-text Production: 10.10.3.1 ~
          br
          span.form-text Development: 10.0.0.21 ~

    div.container-fluid.d-none#migration-node
      div.row
        div.col
          h5 Node Migration
      div.row
        div.col.mb-3
          label 以降元Node Id
          select.form-select(v-model.number="migration_data.from_id")
            option(value="0" disabled) Select Node ID
            option(v-for="n in nodes", :key="n.id", :value="n.id") {{ n.id }}
        div.col-auto.mb-3
          h1
            span(data-feather="arrow-right")
        div.col.mb-3
          label 以降先Node Id
          select.form-select(v-model.number="migration_data.to_id")
            option(value="0" disabled) Select Node ID 
            option(v-for="n in nodes", :key="n.id", :value="n.id") {{ n.id }}


</template>

<script>
import feather from 'feather-icons';

export default {
  name:"Nodes",
  data (){
    return {
      nodes:[
        { id:1, label:"Node1", global_ip:"", local_ip:"10.10.3.1", port:14970, region_id:1, score:0 },
        { id:2, label:"Node2", global_ip:"", local_ip:"10.10.3.2", port:14970, region_id:1, score:0 },
        { id:3, label:"Node3", global_ip:"", local_ip:"10.10.3.3", port:14970, region_id:1, score:0 },
      ],
      new_node_form:null,
      new_node_data:{
        id:null,
        label:"",
        global_ip:"",
        local_ip:"",
        port:14970,
        region_id:1,
        score:0
      },
      migration_form:null,
      migration_data:{
        from_id:0,
        to_id:0
      }
    }
  },
  mounted (){
    feather.replace();
    this.new_node_form = document.getElementById("new-node");
    this.new_node_form.classList.remove("d-none");
    this.new_node_form.remove();
    this.get();
    this.migration_form = document.getElementById("migration-node");
    this.migration_form.classList.remove("d-none");
    this.migration_form.remove();

  },
  methods: {
    get (){
      this.axios.get("/api/nodes")
      .then((response) => {
        this.nodes = response.data;
      })
    },
    recalculate (){
      this.$swal("再計算中…", { button:false });
      this.axios.get("/api/node/recalc")
      .then((response)=>{
        this.nodes = response.data;
        this.$swal.close();
      })
    },
    new_node (){
      this.$swal({ content:this.new_node_form, buttons:[true,true], closeOnClickOutside: false})
      .then((value)=>{
        if(value)
          this.submit_new_node();
      });
    },
    submit_new_node (){
      if (this.check_new_node_data()){
        this.axios.post("/api/node/new", this.new_node_data)
        .then((res)=>{
          this.get();
        })
        .catch((error)=>{
          this.$swal("Fail","","error");
        })
      }
      else{
        this.$swal("","IDとLocalIPは必須かつ重複不可です","error");
      }
    },
    check_new_node_data (){
      return this.new_node_data.id && this.new_node_data.local_ip && 
        this.nodes.filter(e=>e.id==this.new_node_data.id).length==0 &&
        this.nodes.filter(e=>e.local_ip==this.new_node_data.local_ip).length==0;
    },
    migrate (){
      this.$swal({ content:this.migration_form, buttons:[true,true], closeOnClickOutside: false})
      .then((value)=>{
        if (this.migration_data.from_id && this.migration_data.to_id && this.migration_data.from_id != this.migration_data.to_id){
          this.axios.post("/api/node/migration", this.migration_data)
          .then((response)=>{
            this.nodes = response.data;
          })
          .catch((error)=>{
            console.log(error.response);
            this.$swal("Error", "view console", "error");
          });
        }
        else
          this.$swal("Error", "Node IDを指定してください", "error");
      });
    },
    show_jobs (id){
      this.axios.get("/api/node/jobs?id="+id)
      .then((res)=>{
        this.$swal(res.data);
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.users-table {
  th, td {
    vertical-align: middle;
    text-align: center;
  }
  .usage {
    width: 60px;
  }


}
</style>